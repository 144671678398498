<template>
  <section class="container-fluid">
    <div class="row mt-3" v-if="ready">
      <div class="col-md mr-md-2">
        <!-- top card -->
        <div class="row mb-3">
          <div class="col-12">
            <div class="box fix-col">
              <div class="box-body d-flex flex-wrap text-center">
                <div class="flex-fill">
                  <h4
                    v-if="nationalPlanOverview.remaining_days > 0"
                    class="font-weight-bold"
                    :class="{
                      'text-yellow':
                        nationalPlanOverview.remaining_days < 5 &&
                        nationalPlanOverview.remaining_days >= 0,
                    }"
                  >
                    <span class="text-danger">เหลือเวลาทำแผน</span><br>
                    {{ nationalPlanOverview.remaining_days }}
                    <span class="font-weight-normal small-size">วัน</span>
                  </h4>
                  <h4 class="font-weight-bold text-danger" v-else-if="nationalPlanOverview.remaining_days < 0">
                    เลยกำหนด {{ nationalPlanOverview.remaining_days | number_abs }} วัน
                  </h4>
                  <div v-else class="font-weight-bold text-h4 text-danger">
                    วันนี้
                  </div>
                  กำหนดส่งภายใน
                  <div class="helper">
                    ({{ nationalPlanOverview.expiredAt | thai_date }})
                  </div>
                </div>
                <div class="flex-fill">
                  <h4 class="font-weight-bold">
                    {{ nationalPlanOverview.project.submitted }}
                  </h4>
                  ส่งแล้ว
                </div>
                <div class="flex-fill">
                  <h4 class="font-weight-bold">
                    {{ nationalPlanOverview.project.draft }}
                  </h4>
                  กำลังร่าง
                </div>
                <div class="flex-fill">
                  <h4 class="font-weight-bold">
                    {{ nationalPlanOverview.project.notStarted }}
                  </h4>
                  ยังไม่เริ่ม
                </div>
              </div>
              <div class="box-footer text-center">
                <button
                  type="button"
                  @click="showProvinceSelection"
                  class="btn btn-default text-red"
                >
                  ดูรายจังหวัด
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- end top card -->
        <!-- mid card -->
        <div class="row mb-3">
          <div class="col-12 box">
            <div class="box-header col-12 font-weight-bold">ระดับและแผนงานกระทรวงพลังงาน</div>
            <div class="box-body">
              <!-- box 1-2 -->
              <div class="row d-flex">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col">
                      <div class="col-12 p-0">
                        <b-dropdown
                          class="w-100 region-dropwdown border border-light rounded" variant="none" no-caret
                        >
                          <template slot="button-content">
                                    <span class="btn-unstyle text-dark font-weight-normal"
                                    >{{ selectedRegion.title }}
                                    </span>
                            <eva-icon
                              class="float-right"
                              name="chevron-down-outline"
                              fill="#343a40"
                            ></eva-icon>
                          </template>
                          <b-dropdown-item href="#" @click="selectRegion(null)"
                          >ระดับประเทศ
                          </b-dropdown-item
                          >
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item
                            v-for="region in nationalPlanOverview.regions"
                            v-bind:key="region.title"
                            @click="selectRegion(region)"
                            href="#"
                          >
                            {{ region.title }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                      <div class="col-12 d-none d-md-block p-0 mt-3">
                        <div class="border w-100">
                          <map-svg
                            :selectedProvinces="provincesOnMap"
                            ref="regionMap"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <h4 v-if="loadProjectsReady">
                        <span class="font-weight-bold">{{ totalbudget | money(false) }}</span>
                        <small> บาท</small>
                      </h4>
                      <div>งบทั้งหมด</div>
                      <div class="d-flex justify-content-between mt-4">
                        <div>จำนวนโครงการ</div>
                        <div>{{ selectedRegion.totalProject }} โครงการ</div>
                      </div>
                      <div
                        class="d-flex justify-content-between mt-2 border-top border-bottom border-gray pt-2 pb-2"
                      >
                        <div>โครงการที่แล้วเสร็จ</div>
                        <div>{{ selectedRegion.finishedProject }} โครงการ</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-flex w-100">
                    <chart-box
                      v-if="loadProjectsReady"
                      chartType="pie"
                      identifier=''
                      :height="350"
                      title="สถานภาพการดำเนินโครงการ"
                      :box="false"
                      :showOnload="true"
                      :plotdata="projectChart"
                      :include-percent="true"
                      :include-summary="true"
                    >
                    </chart-box>
                  </div>
                </div>
              </div>
              <hr>
              <!-- box 3-4 -->
              <div class="row d-flex">
                <div class="col-md-6 px-2">
                  <div class="d-flex w-100">
                    <chart-box
                      v-if="loadProjectsReady"
                      chartType="column"
                      identifier=''
                      :height="350"
                      title="จำนวนโครงการตามแผนงานกระทรวงพลังงาน"
                      :box="false"
                      :showOnload="true"
                      :plotdata="projectChartByStrategy"
                      :include-summary="true"
                    >
                    </chart-box>
                  </div>
                </div>
                <div class="col-md-6 px-2">
                  <div class="d-flex w-100">
                    <chart-box
                      v-if="loadProjectsReady"
                      chartType="pie"
                      identifier=''
                      :height="350"
                      title="จำนวนโครงการพลังงานทดแทน(RE) และจำนวนโครงการอนุรักษ์พลังงาน(EE)"
                      :box="false"
                      :showOnload="true"
                      :plotdata="projectTypeChart"
                      :include-percent="true"
                      :include-summary="true"
                    >
                    </chart-box>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- mid card -->
        <!-- bottom card -->
        <div class="row mb-3">
          <div class="col-12 box">
            <div class="box-header col-12 d-flex justify-content-between">
              <div class="d-flex col-6 flex align-items-center">
                <span class="font-weight-bold text-nowrap mt-2">รายละเอียดโครงการทั้งหมด</span>
                <multiselect
                  @select="selectNationalPlanYear"
                  :value="currentNationalPlan"
                  deselect-label=""
                  select-label=""
                  track-by="year"
                  label="year"
                  :options="nationalPlanList"
                  :searchable="false"
                  :allow-empty="false"
                  class="year-selection mx-2"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                  >แผนพลังงานปี {{ option.year | thai_year }}
                  </template>
                  <template slot="option" slot-scope="props">
                    <div>ปี {{ props.option.year | thai_year }}</div>
                  </template>
                </multiselect>
                <multiselect
                  @select="selectRegion"
                  :value="selectedRegion.title"
                  deselect-label=""
                  select-label=""
                  track-by="title"
                  label="region"
                  :options="computedRegion"
                  :searchable="false"
                  :allow-empty="false"
                  class="year-selection mx-2"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                  >{{ option }}
                  </template>
                  <template slot="option" slot-scope="props">
                    <div>{{ props.option.title }}</div>
                  </template>
                </multiselect>
              </div>
              <a class="btn btn-outline-secondary btn-sm d-flex align-items-center" :href="downloadExportOverview"
                 target="_blank">
                <svg width="12" height="15" viewBox="0 0 12 15" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.166626 14.6667H11.8333V13H0.166626V14.6667ZM11.8333 5.5H8.49996V0.5H3.49996V5.5H0.166626L5.99996 11.3333L11.8333 5.5Z"
                    fill="#545659" />
                </svg>
                <span class="ml-1 text-nowrap">ดาวน์โหลดไฟล์ข้อมูล</span>
              </a>
            </div>
            <div class="box-body">
              <div class="row d-flex justify-content-between align-items-center">
                <div id="filter-group" class="row d-flex col-10">
                  <div class="col-3">
                    <span class="font-weight-bold">ค้นหา</span>
                    <div class="input-group input-group-sm form-inline">
                      <input v-model="filter.searchValue" type="text" class="form-control custom-height"
                             placeholder="ชื่อโครงการ..">
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group input-group-sm form-inline custom-margin">
                      <span class="font-weight-bold">ประเภทแผนงาน</span>
                      <multiselect
                        v-model="filter.strategy"
                        deselect-label=""
                        select-label=""
                        track-by="title"
                        label="region"
                        :options="computedNationalStrategies"
                        :searchable="false"
                        :allow-empty="false"
                        class="strategy-selection"
                      >
                        <template slot="singleLabel" slot-scope="{ option }"
                        >
                          <span>
                            {{ option.order }} {{ option.order ? '-' : '' }} {{ option.title }}
                          </span>

                        </template>
                        <template slot="option" slot-scope="props">
                          <div>
                            {{ props.option.order }} {{ props.option.order ? '-' : '' }} {{ props.option.title }}
                          </div>
                        </template>
                      </multiselect>
                    </div>
                  </div>
                  <div class="col-3 d-flex flex-column justify-content-center text-center">
                    <span class="font-weight-bold">เลือกประเภทโครงการ</span>
                    <div class="d-flex justify-content-center">
                      <div class="d-flex justify-content-center input-group input-group-sm form-inline">
                        <div class="form-check mx-1">
                          <input v-model="filter.projectType" class="form-check-input" type="radio"
                                 name="projectType"
                                 id="all-projects"
                                 value="ALL" checked>
                          <label class="form-check-label" for="all-projects">
                            ทั้งหมด
                          </label>
                        </div>
                        <div class="form-check mx-1">
                          <input v-model="filter.projectType" class="form-check-input" type="radio"
                                 name="projectType"
                                 id="re-projects"
                                 value="RE">
                          <label class="form-check-label" for="re-projects">
                            RE
                          </label>
                        </div>
                        <div class="form-check mx-1">
                          <input v-model="filter.projectType" class="form-check-input" type="radio"
                                 name="projectType"
                                 id="ee-projects"
                                 value="EE">
                          <label class="form-check-label" for="ee-projects">
                            EE
                          </label>
                        </div>
                        <div class="form-check mx-1">
                          <input v-model="filter.projectType" class="form-check-input" type="radio"
                                 name="projectType"
                                 id="other-projects"
                                 value="other">
                          <label class="form-check-label" for="other-projects">
                            อื่นๆ
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="button-group">
                  <div class="col-1 d-flex align-items-center">
                    <button class="btn btn-secondary mx-1 text-nowrap" type="submit"
                            @click="clearFilter()">
                      ล้างตัวกรอง
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <table class="table table-border table-sm table-hover">
                    <thead class="bg-gray-100 text-light-ipepp">
                    <th style="width: 35%">ชื่อโครงการ</th>
                    <th style="width: 5%">จังหวัด</th>
                    <th class="text-center" style="width: 10%">ประเภทโครงการ</th>
                    <th class="text-center" style="width: 10%">ประเภทแผนงาน</th>
                    <th style="width: 15%">แหล่งงบประมาณ</th>
                    <th class="text-right" style="width: 7.5%">งบที่เสนอ (บาท)</th>
                    <th class="text-right" style="width: 7.5%">งบที่ได้รับ (บาท)</th>
                    <th style="width: 10%" class="text-center">สถานภาพ</th>
                    </thead>
                    <tbody class="text-light-ipepp">
                    <tr v-for="project in projects.data" :key="project.id" @click="showProject(project)"
                        class="pointer">
                      <td>{{ project.name }}</td>
                      <td>{{ project.province.name_th }}</td>
                      <td class="text-center">{{ project.type }}</td>
                      <td class="text-center">
                        <div class="d-flex align-items-center">
                          <span>ประเภทที่ {{ project.national_strategy.order }}</span>
                          <div class="info-icon ml-1 pointer" data-toggle="tooltip" data-placement="top"
                               :title="project.national_strategy.title">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-1 w-1" fill="none" viewBox="0 2 24 24"
                                 stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
                                    d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                          </div>
                        </div>
                      </td>
                      <td>
                        <ul>
                          <li v-for="(source,index) in project.budget_sources" :key="index">
                            {{ displayBudgetSource(source) }}
                          </li>
                        </ul>
                      </td>
                      <td class="text-right">{{ project.budget_expected | auto_number }}
                      <td class="text-right">{{ project.budget_actual | auto_number }}
                      </td>
                      <td class="text-center"
                          :class="project.finished_at== null ? 'text-red':'text-green'">
                        <span class="font-weight-bold">
                          {{ project.finished_at == null ? 'กำลังดำเนินการ' : 'สำเร็จ' }}
                        </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div v-if="loadProjectsReady && projects.data.length === 0"
                       class="row col-12 d-flex justify-content-center">
                    <span>ไม่มีโครงการที่แสดง...</span>
                  </div>
                  <div class="d-flex justify-content-end">
                    <div v-if="loadProjectsReady">
                      <b-pagination-nav
                        v-if="ready"
                        :link-gen="linkGen"
                        :number-of-pages="projects.meta.pagination.total_pages"
                        use-router>
                      </b-pagination-nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end buttom card -->
      </div>
    </div>
    <modals-container @onSelectProvince="selectProvince"></modals-container>
    <vue-element-loading
      :active="!ready"
      spinner="spinner"
      color="#ED110E"
      background-color="transparent"
      class="mt-5"
    />
  </section>
</template>

<script>
import nationalPlanChangedListener from '../../mixins/nationalPlanChangedListener';
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import ProvinceSelection from '@/components/ProvinceSelection.vue';
import Project from '@/components/Project.vue';
import {reduce, sumBy, filter, forEach, has, get} from 'lodash';
import MapSvg from '@/components/MapSvg.vue';
import axios from "axios";
import ChartBox from '@/components/ChartBox.vue';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: 'Overview',

  mixins: [nationalPlanChangedListener],

  components: {
    MapSvg, ChartBox, Multiselect
  },

  data() {
    return {
      selectedRegion: null,
      nationalPlanOverview: {
        expiredAt: '2019-06-12',
        project: {
          submitted: 50,
          draft: 20,
          notStarted: 15,
        },
        regions: [
          {
            region_id: 1,
            title: 'ภาคเหนือ',
            totalProject: 192,
            totalBudget: 290000000,
            finishedProject: 94,
          },
          {
            region_id: 2,
            title: 'ภาคตะวันออกเฉียงเหนือ',
            totalProject: 92,
            totalBudget: 11101000000,
            finishedProject: 23,
          },
        ],
        nationStrategies: [
          {
            title:
              'การจัดหาพลังงานที่เพียงพอและเชื่อถือได้เพื่อตอบสนองความต้องการการส่งเสริมการลงทุน',
            projectCount: 20,
          },
          {
            title: 'การพัฒนาพลังงานที่ยั่งยืนและเป็นมิตรต่อสิ่งแวดล้อม',
            projectCount: 14,
          },
        ],
      },
      projects: [],
      loadProjectsReady: false,
      page: 1,
      selectedPage: 1,
      nationalStrategies: [],
      filter: {
        searchValue: '',
        strategy: {
          id: 0,
          title: 'ทั้งหมด'
        },
        projectType: 'ALL',
      },
      projectByTypeCount: null,
      ready: false,
    };
  },

  created() {
    // this.selectedRegion = this.sumRegion; // mock
    this.fetchData();
    this.fetchProjects();
  },

  mounted() {
  },

  computed: {
    ...mapState(['provinces', 'budgetSources']),
    ...mapGetters(['currentNationalPlan', 'latestNationalPlan', 'nationalPlanList']),
    totalbudget() {
      let thisRegionBudget = this.regions.find(budget => {
        return budget.regionId == this.selectedRegion.region_id;
      });
      if (thisRegionBudget != undefined) {
        return thisRegionBudget.totalBudget;
      }
      return 'ERROR';
    },
    computedNationalStrategies() {
      let newStrategies = [];
      let allStrategies = {
        'id': 0,
        'title': 'ทั้งหมด'
      }
      newStrategies.push(allStrategies);
      this.nationalStrategies.forEach(strategy => {
        newStrategies.push(strategy);
      });
      return newStrategies;
    },
    computedRegion() {
      let newRegions = [];
      let allRgions = {
        'region_id': 0,
        'title': 'ระดับประเทศ'
      };
      newRegions.push(allRgions);
      this.nationalPlanOverview.regions.forEach(region => {
        newRegions.push(region)
      });
      return newRegions;
    },
    projectChart() {
      return {
        data: [
          ['โครงการ', 'จำนวนโครงการ'],
          ['กำลังดำเนินการ', this.selectedRegion.totalProject - this.selectedRegion.finishedProject],
          ['เสร็จสิ้น', this.selectedRegion.finishedProject],
        ],
        title: '',
        unit: 'โครงการ',
      }
    },
    projectTypeChart() {
      return {
        data: [
          ['โครงการ', 'จำนวนโครงการ'],
          ['โครงการ EE', this.projectByTypeCount.ee],
          ['โครงการ RE', this.projectByTypeCount.re],
          ['โครงการ อื่นๆ', this.projectByTypeCount.other],
        ],
        title: '',
        unit: 'โครงการ',
      }
    },
    projectChartByStrategy() {
      let data = [
        ['แผนงานกระทรวงพลังงาน', 'จำนวนโครงการ'],
      ];
      this.nationalPlanOverview.nationStrategies.forEach((strategy, index) => {
        let count = this.getProjectCountByNationalStrategyId(strategy.id)
        let newData = [strategy.title, count];
        data.push(newData);
      })
      return {
        data: data,
        title: '',
        unit: 'โครงการ',
      }
    },
    sumRegion() {
      return {
        region_id: 0,
        title: 'ระดับประเทศ',
        // budget: sumBy(this.nationalPlanOverview.regions, 'budget'),
        totalProject: sumBy(this.nationalPlanOverview.regions, 'totalProject'),
        totalBudget: sumBy(this.nationalPlanOverview.regions, 'totalBudget'),
        projectsCountByStrategies: reduce(
          this.nationalPlanOverview.regions,
          (result, region) => {
            forEach(
              region.projectsCountByStrategies,
              (project_count, national_strategy_id) => {
                if (has(result, national_strategy_id)) {
                  result[national_strategy_id] += project_count;
                } else {
                  result[national_strategy_id] = project_count;
                }
              }
            );
            return result;
          },
          {}
        ),
        finishedProject: sumBy(
          this.nationalPlanOverview.regions,
          'finishedProject'
        ),
      };
    },
    provincesOnMap() {
      if (this.selectedRegion.region_id) {
        return filter(this.provinces, {
          region_id: this.selectedRegion.region_id,
        });
      }
      return [];
    },
    downloadExportOverview() {
      let selectedRegionId = 0;
      if (this.selectedRegion) {
        selectedRegionId = this.selectedRegion.region_id;
      }
      return '/api/national-plans/' + this.currentNationalPlan.id + '/overview/export?projectType=' + this.filter.projectType +
        '&regionId=' + selectedRegionId +
        '&projectType=' + this.filter.projectType +
        '&strategy=' + this.filter.strategy.id +
        '&search=' + this.filter.searchValue;
    },
  },
  watch: {
    'filter.projectType': function () {
      this.submitFilter();
    },
    'filter.strategy': function () {
      this.submitFilter();
    },
    'filter.searchValue': function () {
      this.submitFilter();
    },
    currentNationalPlan() {
      this.clearFilter();
    },
    page() {
      this.page = this.$route.query.page;
      this.selectedPage = this.$route.query.page ?? '1';
    },
    selectedPage() {
      this.loadProjectsReady = false;
      this.fetchProjects();
    },
    selectedRegion() {
      this.updateProjectChart();
    }
  },
  methods: {
    ...mapActions('pso', ['fetchNationalPlanOverview']),
    ...mapMutations(['setCurrentNationalPlan']),
    getColor(index) {
      let colors = [
        '#003F5C', '#2F4B7C', '#665191', '#A05195', '#D45087', '#F95d6A', '#FF7C43', '#FFA600',
      ];
      return colors[index];
    },
    selectNationalPlanYear(option, id) {
      this.setCurrentNationalPlan(option);
    },
    currentNationalPlanChanged() {
      this.fetchData();
      this.fetchProjects();
    },
    showProject(project) {
      this.$modal.show(
        Project,
        {
          name: 'project_details',
          project: project,
        },
        {
          name: 'project_details',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '80%',
          transition: 'fade',
        }
      );
    },
    clearFilter() {
      this.filter.projectType = 'ALL';
      this.filter.strategy = {
        id: 0,
        title: 'ทั้งหมด'
      };
      this.filter.searchValue = '';
      this.selectedPage = 1;
      this.selectRegion(null);
      this.submitFilter();
    },
    updateProjectChart() {
      this.projectChart.data[1][1] = this.selectedRegion.totalProject - this.selectedRegion.finishedProject;
      this.projectChart.data[2][1] = this.selectedRegion.finishedProject;
    },
    submitFilter() {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if (this.filter.searchValue != '') {
          this.selectedPage = 1;
          this.page = 1;
        }
        this.fetchProjects();
      }, 500);
    },
    linkGen(pageNum) {
      this.page = pageNum;
      return pageNum === 1 ? '?' : `?page=${pageNum}`;
    },
    fetchProjects() {
      let selectedRegionId = 0;
      if (this.selectedRegion) {
        selectedRegionId = this.selectedRegion.region_id;
      }
      let url = '/api/national-plans/' + this.currentNationalPlan.id + '/overview/projects?page=' + this.selectedPage +
        '&regionId=' + selectedRegionId +
        '&projectType=' + this.filter.projectType +
        '&strategy=' + this.filter.strategy.id +
        '&search=' + this.filter.searchValue;
      axios.get(url).then((response) => {
        this.projects = response.data.projectsData;
        this.nationalStrategies = response.data.nationalStrategies;
        this.projectByTypeCount = response.data.projectByTypeCount;
        this.loadProjectsReady = true;
        this.regions = response.data.regions;
        this.page = 1;
      }).catch(error => {
        console.log('-----------------');
        console.log(error);
        console.log('-----------------');
      });
    },
    displayBudgetSource(source) {
      let displaySource = this.budgetSources.find((budgetSource) => {
        return budgetSource.id == source.type
      })
      if (displaySource == undefined) {
        return "-";
      }

      if (source.type === 6) {
        return displaySource.title + ' (' + source.other + ')';
      }
      return displaySource.title;
    },
    fetchData() {
      this.ready = false;
      this.fetchNationalPlanOverview(this.currentNationalPlan).then(
        (nationalPlanOverview) => {
          this.nationalPlanOverview = nationalPlanOverview;
          this.selectedRegion = this.sumRegion;
          this.updateProjectChart();
          this.ready = true;
        }
      );
    },
    selectRegion(region) {
      if (region && region.region_id != 0) {
        this.selectedRegion = region;
      } else {
        this.selectedRegion = this.sumRegion;
      }
      this.$refs.regionMap.selectRegion(this.provincesOnMap);
      this.updateProjectChart();
      this.submitFilter();
    },
    getProjectCountByNationalStrategyId(nationalStrategyId) {
      return this.selectedRegion.projectsCountByStrategies[nationalStrategyId];
    },
    selectProvince(province) {
      this.$store.commit('pso/setCurrentProvince', province);
      this.$router.push({name: 'pso.province_summary'});
    },
    showProvinceSelection() {
      this.$modal.show(
        ProvinceSelection,
        {
          name: 'province_selection',
        },
        {
          name: 'province_selection',
          pivotY: 0.01,
          adaptive: true,
          scrollable: true,
          height: 'auto',
          width: '70%',
          transition: 'fade',
        }
      );
    },
  },
};
</script>

<style lang="scss">
.custom-margin {
  margin-top: -3px;
}

.small-size {
  font-size: 0.8rem;
}

.region-dropwdown {
  box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.1);

  .dropdown-menu {
    width: 100%;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}

.eva-hover {
  margin-top: -0.1rem;
}

.custom-control-input:checked ~ .custom-control-label.red::before {
  background-color: red;
}

.page-item.active .page-link {
  background-color: #F3F4F6 !important;
  border-color: #E5E7EB !important;
}

.page-link {
  color: #4B5563 !important;
}

.info-icon {
  width: 20px
}

.strategy-selection {
  min-width: 500px;
}

.custom-height {
  height: 40px !important;
}
</style>
