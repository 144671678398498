<template>
  <div class="w-100" :class="box ? 'box':''">
    <div class="" :class="box ? 'box-header':''">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <b>{{ identifier }}</b
          ><span class="ml-3 font-weight-bold">{{ title || '' }}</span>
          <div
            v-if="tooltip"
            class="text-gray text-medium pointer ml-3"
            v-tooltip="{
              content: tooltip,
            }"
          >
            <u>ข้อมูลเพิ่มเติม</u>
          </div>
        </div>
        <div
          v-if="ready && !error"
          class="d-flex align-items-center justify-content-end ml-3"
        >
          <a
            class="btn-red mr-3"
            :href="chartUrl"
            target="_blank"
            v-show="show"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22">
              <path d="M4 18h14V4H4v14zM6 6h10v10H6V6z" />
              <path
                d="M2 16H0v6h6v-2H2zM2 2h4V0H0v6h2zM20 20h-4v2h6v-6h-2zM16 0v2h4v4h2V0z"
              />
            </svg>
          </a>
          <div class="mr-3 pointer" @click="isChart = !isChart" v-show="show">
            <svg
              v-if="isChart"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 80 80"
            >
              <path
                d="M0 2.5v75h80v-75H0zm77 17H19v-14h58v14zm-38.75 3H56.5v52H38.25v-52zm-3 52H19v-52h16.25v52zM16 5.5v14H3v-14h13zm-13 17h13v52H3v-52zm56.5 52v-52H77v52H59.5z"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 129 129"
            >
              <path
                d="M118.4 114.4H14.6V10.6c0-2.3-1.8-4.1-4.1-4.1s-4.1 1.8-4.1 4.1v107.9c0 2.3 1.8 4.1 4.1 4.1h107.9c2.3 0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1z"
              />
              <path
                d="M54.3 98.6v-59c0-2.3-1.8-4.1-4.1-4.1s-4.1 1.8-4.1 4.1v59c0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1zM34.5 98.6v-59c0-2.3-1.8-4.1-4.1-4.1s-4.1 1.8-4.1 4.1v59c0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1zM93.9 98.6v-59c0-2.3-1.8-4.1-4.1-4.1s-4.1 1.8-4.1 4.1v59c0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1zM113.7 98.6V69.1c0-2.3-1.8-4.1-4.1-4.1s-4.1 1.8-4.1 4.1v29.5c0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1zM74.1 98.6V69.1c0-2.3-1.8-4.1-4.1-4.1s-4.1 1.8-4.1 4.1v29.5c0 2.3 1.8 4.1 4.1 4.1s4.1-1.8 4.1-4.1z"
              />
            </svg>
          </div>
          <button
            style="min-width: 122px"
            type="button"
            class="btn btn-outline-secondary text-medium d-flex flex-grow-1"
            v-if="show"
            v-promise-btn
            @click="requestChartImageUrl"
          >
            <span class="flex-shrink-1 text-nowrap">สร้างลิงค์รูปภาพ</span>
          </button>
          <collapse-button v-model="show" :class="box ?'':'d-none'"></collapse-button>
        </div>
        <vue-element-loading
          v-else-if="!ready && !error"
          :active="!ready"
          spinner="spinner"
          color="#ED110E"
          class="position-relative mr-3 mb-2"
          background-color="transparent"
        />
        <div v-else class="text-danger">การเรียกข้อมูลล้มเหลว</div>
      </div>
      <div class="mt-2 d-flex" v-if="imageUrl">
        <input
          type="text"
          disabled
          class="border rounded text-light-ipepp bg-lighter-blue flex-grow-1 px-2 text-small"
          :value="imageUrl"
        />
        <div>
          <button class="btn btn-light p-1 ml-1" v-clipboard:copy="imageUrl" v-clipboard:success="copied">
            คัดลอก
          </button>
          <!--imageUrl must be the same as domain url in order popup save window-->
          <a :href="imageUrl" class="btn btn-light p-1 ml-1" download>Download</a>
        </div>
      </div>
    </div>
    <transition name="fadeHeight">
      <div v-if="show">
        <div class="d-flex flex-column" :class="{'box-body':box}">
          <iframe
            v-show="isChart"
            :src="chartUrl"
            :height="height + 'px'"
            marginwidth="0"
            marginheight="0"
            frameborder="0"
            vspace="0"
            hspace="0"
            seamless="seamless"
          >
          </iframe>
          <div class="m-3" v-show="!isChart">
            <div class="table-responsive">
              <table class="table table-bordered table-striped">
                <thead>
                <tr>
                  <th
                    scope="col"
                    v-for="(column, index) in tableHeader"
                    :key="index"
                  >
                    <span>{{ column }}</span>
                  </th>
                  <th v-if="includePercent" class="text-right">%</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row, index) in tableBody" v-bind:key="index">
                  <td v-for="(item, index) in row" v-bind:key="index">
                    <template v-if="tableFirstColumnNoFilter && index == 0">
                      {{ item }}
                    </template>
                    <template v-else>
                      <div v-if="index===0">
                        {{ item | auto_number }}
                      </div>
                      <div v-else class="text-right">
                        {{ item | auto_number }}
                      </div>
                    </template>
                  </td>
                  <td v-if="includePercent" class="text-right">
                    <span>{{ percent(row) }}%</span>
                  </td>
                </tr>
                <tr v-if="includeSummary">
                  <td scope="col" v-for="(column, index) in tableHeader" :key="index">
                    <div v-if="index===0">
                      <span>ทั้งหมด</span>
                    </div>
                    <div v-if="index===1" class="text-right">
                      <span>{{ total }}</span>
                    </div>
                  </td>
                  <td v-if="includePercent" class="text-right">100.00%</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="mt-4 mb-3 text-medium text-secondary text-center"
            v-if="helperText"
          >
            <div>{{ helperText }}</div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
import {head, drop} from 'lodash';
import CollapseButton from '@/components/CollapseButton.vue';

export default {
  name: 'ChartBox',

  components: {
    CollapseButton,
  },

  props: {
    includePercent: {
      type: Boolean,
      default: false
    },
    includeSummary: {
      type: Boolean,
      default: false
    },
    box: {
      type: Boolean,
      default: true
    },
    showOnload: {
      type: Boolean,
      default: false
    },
    chartType: {
      type: String,
      required: true,
    },
    identifier: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    plotdata: {
      type: Object,
      default: null,
    },
    fetchPromise: {
      type: Function,
      default: null,
    },
    height: {
      type: Number,
      default: 600,
    },
    tooltip: {
      type: String,
      default: null,
    },
    helperText: {
      type: String,
      default: null,
    },
    tableFirstColumnNoFilter: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      chartData: this.plotdata,
      baseUrl: process.env.VUE_APP_BACKEND_URL,
      imageUrl: null,
      show: this.showOnload,
      isChart: true,
      ready: this.plotdata ? true : false,
      error: false,
    };
  },

  mounted() {
    this.fetch();
  },
  watch: {
    plotdata() {
      this.chartData = this.plotdata;
    }
  },
  computed: {
    ...mapState(['locales']),
    ...mapGetters(['currentLocaleDisplay']),
    total() {
      let total = 0;
      this.tableBody.forEach(row => {
        total = total + parseFloat(row[1]);
      });
      return total;
    },
    chartUrl() {
      return (
        this.baseUrl + `/chart/${this.chartType}?` + this.json(this.chartData)
      );
    },
    chartImageUrl() {
      return `/chart/${this.chartType}?type=image&` + this.json(this.chartData);
    },
    tableHeader() {
      return head((this.chartData || []).data);
    },
    tableBody() {
      return drop((this.chartData || []).data);
    },
  },

  methods: {
    ...mapActions('peno', ['fetchChartImageUrl']),
    percent(item) {
      let percent = parseFloat(item[1]) / this.total * 100;
      return percent.toFixed(2);
    },
    fetch() {
      if (this.fetchPromise) {
        this.ready = false;
        this.fetchPromise()
          .then((data) => {
            this.chartData = data;
            this.ready = true;
          })
          .catch(() => {
            this.error = true;
          });
      }
    },
    copied() {
      this.$toasted.global.copied();
    },
    json(params) {
      return 'data=' + encodeURIComponent(JSON.stringify(this.chartData));
    },
    requestChartImageUrl() {
      if (this.imageUrl) return;
      return this.fetchChartImageUrl(this.chartImageUrl)
        .then((data) => {
          this.imageUrl = data;
        })
        .catch((err) => {
          console.error(err);
          this.$toasted.global.display_error({
            message: err,
          });
        });
    },
  },
};
</script>
