<template>
  <a
    class="pointer link-unstyle sort-by"
    @click="sorting"
    :class="[{ active: isActive }, orderClass]"
    ><slot></slot
  ></a>
</template>

<script>
export default {
  name: 'SortingColumn',

  props: {
    sortKey: {
      type: String,
      required: true,
    },
    sorted: {
      type: Object,
      required: true,
    },
  },

  created() {},

  computed: {
    isActive() {
      return this.sortKey == this.sorted.key;
    },
    orderClass() {
      return this.isActive ? this.sorted.order : '';
    },
  },

  methods: {
    sorting() {
      var key = this.sortKey;
      if (!this.sorted.key || this.sorted.key != key) {
        this.sorted.key = key;
        this.sorted.order = 'asc';
      } else if (key == this.sorted.key) {
        this.sorted.order = this.sorted.order == 'asc' ? 'desc' : 'asc';
      }
      this.$emit('sortedColumn');
    },
  },
};
</script>

<style lang="scss"></style>
