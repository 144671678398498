<template>
  <div class="box">
    <div class="box-header">
      <span class="text-h4 font-weight-bold">เลือกจังหวัด</span>
      <div class="text-h4 d-inline position-relative w-25 ml-4">
        <vue-element-loading
          :active="loading"
          spinner="bar-fade-scale"
          size="20"
        />
      </div>
      <button
        @click="$modal.hide(name)"
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <eva-icon name="close-outline"></eva-icon>
      </button>
    </div>
    <div class="box-body">
      <div class="w-100">
        <div class="input-group">
          <input
            class="form-control border-right-0 border"
            type="text"
            placeholder="ค้นหาจากชื่อจังหวัด"
            v-model="search"
            v-on:keyup.enter="directEnterSearch"
          />
          <span class="input-group-append">
            <div class="input-group-text bg-transparent">
              <eva-icon name="search-outline"></eva-icon>
            </div>
          </span>
        </div>
      </div>
      <table class="table table-borderless" v-if="filteredProvinces.length">
        <thead class="header">
          <tr is="sorting-header" :headers="headers" @onSorting="sorting"></tr>
        </thead>
        <tbody class="body">
          <tr
            class="province-choice"
            @click="selectProvince(province)"
            v-for="province in filteredProvinces"
            v-bind:key="province.iso3166_2"
          >
            <td class="">{{ province.name_th }}</td>
            <td :class="statusClass(province.status)">
              {{ statusDisplay(province.status) }}
            </td>
            <td>
              <report-progress-bar
                :progress="province.progress"
                v-if="province.progress != null"
              >
              </report-progress-bar>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-else class="my-5">
        <div class="text-light-ipepp text-center">ไม่พบคำที่ค้นหา</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import SortingHeader from '@/components/SortingHeader.vue';
import ReportProgressBar from '@/components/ReportProgressBar.vue';
import { orderBy } from 'lodash';

export default {
  name: 'ProvinceSelection',

  components: {
    SortingHeader,
    ReportProgressBar,
  },

  props: {
    name: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      search: '',
      headers: [
        { title: 'จังหวัด', key: 'name_th' },
        { title: 'สถานะ', key: 'status' },
        { title: 'ความคืบหน้าของแผน', key: 'progress' },
      ],
      sorted: {
        key: null,
        order: 'asc',
      },
      loading: false,
    };
  },

  created() {
    this.init();
  },

  computed: {
    ...mapGetters(['currentNationalPlan']),
    ...mapState(['provinces']),
    filteredProvinces() {
      let filtered = this.provinces;
      if (this.search != null && this.search != '') {
        filtered = this.provinces.filter((province) => {
          return (
            province.name_th
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
          );
        });
      }
      return this.sorted.key
        ? orderBy(filtered, [this.sorted.key], [this.sorted.order])
        : filtered;
    },
  },

  methods: {
    ...mapActions(['fetchProvincesStatus']),
    init() {
      this.loading = true;
      this.fetchProvincesStatus(this.currentNationalPlan).finally(() => {
        this.loading = false;
      });
    },
    sorting(sorted) {
      this.sorted = sorted;
    },
    selectProvince(province) {
      this.$emit('onSelectProvince', province);
      this.$modal.hide(this.name);
    },
    directEnterSearch() {
      if (this.filteredProvinces.length == 1) {
        this.selectProvince(this.filteredProvinces[0]);
      }
    },
    statusClass(status) {
      switch (status) {
        case 1:
          return 'text-red';
        case 2:
          return 'text-yellow';
        default:
          return 'text-green';
      }
    },
    statusDisplay(status) {
      switch (status) {
        case 1:
          return 'ยังไม่ส่ง';
        case 2:
          return 'รอการประเมิน';
        case 3:
          return 'ประเมินแล้ว';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control:focus {
  box-shadow: none;
}
.province-choice {
  cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
  }
}
</style>
