<template>
  <tr>
    <th scope="col" v-for="header in headers" :key="header.key">
      <sorting-column
        v-if="header.title"
        :sort-key="header.key"
        :sorted="sorted"
        @sortedColumn="sorting"
        >{{ header.title }}</sorting-column
      >
      <span v-else></span>
    </th>
  </tr>
</template>

<script>
import SortingColumn from '@/components/SortingColumn.vue';

export default {
  name: 'SortingHeader',

  components: {
    SortingColumn,
  },

  props: {
    headers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      sorted: {
        key: null,
        order: 'asc',
      },
    };
  },

  computed: {},

  methods: {
    sorting() {
      this.$emit('onSorting', this.sorted);
    },
  },
};
</script>
