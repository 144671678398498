<template>
  <div class="d-flex align-items-center">
    <div class="progress w-75 mr-2" style="height: 5px">
      <div
        class="progress-bar"
        :class="colorClass"
        role="progressbar"
        :style="{ width: progress + '%' }"
        :aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <div class="font-weight-bold">{{ progress | percentage(100) }}</div>
  </div>
</template>

<script>
export default {
  name: 'ReportProgressBar',

  props: {
    progress: {
      type: Number,
      default: null,
    },
  },

  computed: {
    colorClass() {
      if (this.progress >= 100) {
        return 'bg-green';
      } else if (this.progress > 60) {
        return 'bg-dark-yellow';
      }
      return 'bg-light-red';
    },
  },
};
</script>

<style lang="scss"></style>
