<template>
  <div class="box">
    <div class="box-header">
      <span class="text-h4 font-weight-bold">รายละเอียดโครงการ จังหวัด{{ project.province.name_th }}</span>
      <div class="text-h4 d-inline position-relative w-25 ml-4">
        <vue-element-loading
          :active="loading"
          spinner="bar-fade-scale"
          size="20"
        />
      </div>
      <button
        @click="$modal.hide(name)"
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <eva-icon name="close-outline"></eva-icon>
      </button>
    </div>
    <div class="box-body">
      <div class="row">
        <div class="col-md-12">
          <div class="box-body">
            <div class="row border-bottom">
              <div class="col d-flex align-items-start justify-content-between flex-nowrap">
                <p class="text-h5">{{ project.name }}</p>
                <p>{{ currentNationalPlan.start_budget_year }}</p>
              </div>
            </div>
            <div class="row mt-2 pb-2 d-flex justify-content-center">
              <div class="col-4 col-md-3">
                <span class="font-weight-bold">ปี</span>
              </div>
              <div class="col-4 col-md-4 text-center">
                <span class="font-weight-bold">งบ ฯ ที่เสนอขอ</span> (บาท)
              </div>
              <div class="col-4 col-md-4 text-center">
                <span class="font-weight-bold">งบ ฯ ที่ได้รับ</span> (บาท)
              </div>
            </div>
            <div v-if="project.project_budgets.length == 0" class="row mt-2 pb-2 d-flex justify-content-center">
              <div class="col-4 col-md-3 font-weight-bold">
                -
              </div>
              <div class="col-4 col-md-4 text-center">
                <div>
                  -
                </div>
              </div>
              <div class="col-4 col-md-4 text-center">
                <div>
                  -
                </div>
              </div>
            </div>
            <div
              v-if="project.project_budgets.length > 0"
            >
              <div
                class="row mt-2 pb-2 d-flex justify-content-center"
                v-for="(budget,index) in project.project_budgets" :key="index"
              >
                <div class="col-4 col-md-3 font-weight-bold">
                  ปี {{ budget.year }}
                </div>
                <div class="col-4 col-md-4 text-right">
                  <div>
                    {{ budget.budget_expected | auto_number }}
                  </div>
                </div>
                <div class="col-4 col-md-4 text-right">
                  <div>
                    {{ budget.budget_actual == null ? 0 : budget.budget_actual | auto_number }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="row d-flex justify-content-center border-bottom pb-3 mb-3"
            >
              <div class="font-weight-bold"><u>แหล่งงบ</u> &nbsp;&nbsp;</div>
              <div>
                <ul>
                  <li v-for="(source,sourceIndex) in project.budget_sources" :key="sourceIndex">
                      <span v-for="listSource in budgetSources" :key="listSource.id">
                        <span v-if="listSource.id == source.type">
                           {{ listSource.title }}
                          <span v-if="source.type == 6">
                           ({{ source.other }})
                          </span>
                        </span>
                      </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row mt-2 pb-2 border-bottom">
              <div class="col-md-6 plan-target-result">
                <div class="font-weight-bold text-gray">
                  เป้าหมายผลลัพธ์สำคัญตลอดโครงการ (1 ปี)
                </div>
                <ul>
                  <li>
                    <div>
                      {{ project.target_result_value[0].category_title }} - {{ project.target_result_value[0].name }}
                      {{
                        project.target_result_value[0].pivot.expected_value == null ? 0 : project.target_result_value[0].pivot.expected_value | auto_number
                      }} toe
                    </div>
                  </li>
                </ul>
              </div>
              <div class="col-md-6">
                <div class="font-weight-bold">
                  ผลลัพธ์จริงในปี
                </div>
                <ul>
                  <li>
                    {{ project.target_result_value[0].category_title }} - {{ project.target_result_value[0].name }}
                    {{
                      project.target_result_value[0].pivot.result_value == null ? 0 : project.target_result_value[0].pivot.result_value
                    }}
                    toe
                  </li>
                </ul>
              </div>
            </div>
            <div class="row mt-2 pb-2 border-bottom">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-4">
                    <div class="text-gray">ตัวชี้วัด</div>
                  </div>
                  <div class="col-4">
                    <div class="text-gray">ผลผลิตที่คาดหวัง</div>
                  </div>
                  <div class="col-4">
                    <div class="text-gray">ผลผลิตจริง</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div>
                      {{ indicatorType }}
                    </div>
                  </div>
                  <div class="col-4">
                    <div
                      v-for="targetIndicator in project.project_target_indicators"
                      :key="targetIndicator.id"
                    >
                      {{ targetIndicator.indicator_value_expected | auto_number }}
                      ({{ targetIndicator.indicator_unit_expected == null ? 'toe' : targetIndicator.indicator_unit_expected }})
                    </div>
                  </div>
                  <div class="col-4">
                    <div
                      v-for="targetIndicator in project.project_target_indicators"
                      :key="targetIndicator.id"
                    >
                      {{ targetIndicator.indicator_value_result | auto_number }}
                      ({{ targetIndicator.indicator_unit_expected == null ? 'toe' : targetIndicator.indicator_unit_expected }})
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="font-weight-bold">อุปสรรคสำคัญ</div>
                <!--                {{-->
                <!--                  project.target_result_value[0].pivot.result == null ? '-' : project.target_result_value[0].pivot.result-->
                <!--                }}-->
                {{ project.threat == null ? '-' : project.threat }}
              </div>
            </div>
            <div class="row mt-2 pb-2 d-flex justify-content-end">
              <!--                hidden button-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';

import {orderBy} from 'lodash';

export default {
  name: 'Project',
  components: {},

  props: {
    name: {
      type: String,
      default: '',
    },
    project: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,
    };
  },

  created() {
  },

  computed: {
    ...mapGetters(['currentNationalPlan']),
    ...mapState(['provinces']),
    ...mapState('peno', ['budgetSources']),
    indicatorType() {
      if (this.project.target_result_value[0].target.type == 1) {
        return 'RE'
      }
      if (this.project.target_result_value[0].target.type == 2) {
        return 'EE'
      }
      return this.project.target_result_value[0].target.type_other;
    }
  },

  methods: {
    ...mapActions(['fetchProvincesStatus']),
  },
};
</script>

<style lang="scss" scoped>
.form-control:focus {
  box-shadow: none;
}

.province-choice {
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }
}
</style>
