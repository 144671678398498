<template>
  <button
    type="button"
    @click="$emit('input', !value)"
    class="ml-2 btn btn-outline-secondary text-medium"
  >
    <span v-show="value">ซ่อน</span>
    <span v-show="!value">แสดง</span>
  </button>
</template>

<script>
export default {
  name: 'collapse-button',
  props: ['value'],
};
</script>
