export default {
  created() {
    this.$store.watch(
      (state, getters) => state.currentNationalPlanId,
      (newValue, oldValue) => {
        this.currentNationalPlanChanged();
      }
    );
  },
  methods: {
    currentNationalPlanChanged() {
      console.log('Year Changed');
    },
  },
};
